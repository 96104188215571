export type Routes = {
  announcements: {
    create: string;
    edit: string;
    main: string;
  };
  categories: {
    create: string;
    edit: string;
    main: string;
  };
  faqs: {
    create: string;
    edit: string;
    main: string;
  };
  getStarted: string;
  helpCenter: {
    main: string;
  };
  login: string;
  modules: {
    create: string;
    edit: string;
    main: string;
  };
  notFound: string;
  root: string;
  rules: {
    create: string;
    edit: string;
    main: string;
  };
  users: {
    create: string;
    edit: string;
    main: string;
  };
};

export const APP_ROUTES: Routes = {
  announcements: {
    create: 'create',
    edit: 'edit/:id',
    main: '/announcements',
  },
  categories: {
    create: 'create',
    edit: 'edit/:categoryName',
    main: '/categories',
  },
  faqs: {
    create: 'create/:type/:id?',
    edit: 'edit/:type/:id',
    main: '/faqs',
  },
  getStarted: '/get-started',
  helpCenter: {
    main: '/help-center',
  },
  login: '/login',
  modules: {
    create: 'create',
    edit: 'edit/:moduleName',
    main: '/modules',
  },
  notFound: '*',
  root: '/',
  rules: {
    create: 'create',
    edit: 'edit/:id',
    main: '/rules',
  },
  users: {
    create: 'create',
    edit: 'edit/:userEmail',
    main: '/users',
  },
} as const;
